<template>
  <v-card>
    <v-card-title> Create Marketing Brochure </v-card-title>
    <v-card-text>
      <div class="d-flex mt-4" v-if="filteredGiclees">
        <v-autocomplete
          class="mr-2"
          v-model="selectedArtist"
          :items="artists"
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          item-text="artist"
          item-value="artistCode"
          :label="'Artist'"
        ></v-autocomplete>
      </div>
      <v-btn color="success" class="mr-2" @click.stop="downloadCatalog()">
        Download All
        <v-icon class="ml-4">
          mdi-download
        </v-icon>
      </v-btn>
      <v-btn v-if="selectedArtist" color="success" @click.stop="downloadCatalog(selectedArtist)">
          Download Artist Brochure
          <v-icon class="ml-4">
            mdi-download
          </v-icon>
      </v-btn>
      <h3 class="my-2" v-if="selectedArtist">Note: Artwork images are still being sorted out</h3>
      <v-data-table
      v-if="selectedArtist"
      :items="filteredGiclees[0].artworks"
      item-key="artwork"
      :headers="headers"
      :footer-props="{
        itemsPerPageOptions: [50, 100, 250],
      }"
      dense
    >
      <template v-slot:item.gicleeBrochure="{ item }">
        <v-checkbox @change="setGicleeBrochure(item)" v-model="item.gicleeBrochure" />
      </template>
      <template v-slot:[`item.artworkImage`]="{ item }">
        <div class="d-flex">
        <v-img
          height="100px"
          contain
          :src="`${imageUrl}/100px/${item.artworkImage}`"
        ></v-img>
      </div>
      </template>
      <template v-slot:[`item.xyz`]="{ item }">
        <div class="d-flex">
        <v-img
          max-height="100px"
          contain
          :src="`${imageUrl}/artwork-images/100px/${item.artworkCode}.jpg`"
        ></v-img>
      </div>
      </template>
    </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ArtistApi from "@/api/admin/artist";
import ArtworkApi from "@/api/artwork";
import ProductApi from "@/api/admin/product"

export default {
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      selectedArtist: null,
      giclees: [],
      headers: [
        { text: "Current Image", value: "artworkImage" },
        { text: "New Image", value: "xyz" },
        { text: "Title", value: "title" },
        { text: "Artwork", value: "artworkCode" },
        { text: "Sales", value: "sales" },
        { text: "Giclee Brochure", value: "gicleeBrochure" }
      ],
    };
  },
  async created() {
    ArtistApi.loadArtistGiclees({ artistCodes: [] }).then((res) => {
      this.giclees = res.data;
    });
  },
  computed: {
    filter() {
      const obj = {};
      this.selectedArtist && (obj.artistCode = this.selectedArtist);
      return obj;
    },
    filteredGiclees() {
      return _.filter(this.giclees, this.filter);
    },
    artists() {
      return _.uniq(
        this.giclees.map((a) => {
          return { artist: a.artist, artistCode: a.artistCode };
        })
      );
    },
  },
  methods: {
    async downloadCatalog(artistCode) {
      ProductApi.getCatalogPDF({artistCodes: artistCode ? [artistCode] : []}).then((results) => {
      })
    },
    async setGicleeBrochure ({artworkCode, gicleeBrochure}) {
      ArtworkApi.updateArtwork({ artworkCode, gicleeBrochure }).then((res) => {
      console.log(res)
    });
    console.log(value)
    }
  }

};
</script>
